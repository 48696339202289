
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default defineComponent({
  name: "finance-undewriters-list",
  components: {},
  data() {
    return {
      undewriters: [],
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Underwriters", ["Users"]);
    });
  },
  created() {
    this.getUnderwriters();

    //Set page title
    document.title = "Underwriters List | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getUnderwriters() {
      //Fetch underwriter dashboard data
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.FINANCE_UNDERWRITERS_ROUTE)
          .then(({ data }) => {
            //Assign data to props
            this.undewriters = data.data;
            if (this.undewriters) {
              this.displayDatatable();
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    displayDatatable() {
      //Add jQuery datatable
      $("#basicExample").DataTable({
        iDisplayLength: 10,
        language: {
          searchPlaceholder: "Search...",
          sSearch: "",
          lengthMenu: "_MENU_ items/page",
          // "lengthMenu": "Display _MENU_ records per page",
          zeroRecords: "No matching records found",
          // "info": "Showing page _PAGE_ of _PAGES_",
          infoEmpty: "No records available",
          infoFiltered: "(filtered from _MAX_ total records)",
        },
        processing: true,
      });
    },
  },
});
